<div class="margin-side-bar">
  <div class="header-container">
    <div class="mt-20 mb-20">
      <app-back-button />
    </div>
    <app-project-page-title
      title="Add Project"
      [projectCurtailment]="true"
      (projectCurtailmentChange)="onProjectCurtailmentChange($event)"
    />
  </div>
  @if (hasCreateProjectPermission) {
    <div class="mt-20">
      <app-project-form
        [regions]="regions"
        [projectCurtailment]="projectCurtailment"
        submitButtonLabel="Save & Close"
        (emitOnSubmit)="createNewProject($event)"
        (emitOnCancel)="cancel()"
      />
    </div>
  }
</div>
