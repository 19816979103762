import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageTitleComponent } from '@shared/components/page-title/page-title.component';
import { ProjectCurtailmentComponent } from '../project-curtailment/project-curtailment.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-project-page-title',
  standalone: true,
  imports: [PageTitleComponent, ProjectCurtailmentComponent, CommonModule],
  templateUrl: './project-page-title.component.html',
  styleUrl: './project-page-title.component.scss',
})
export class ProjectPageTitleComponent {
  @Input() title = '';

  @Input() projectCurtailment = false;

  @Input() disabledCurtailment? = false;

  @Output() projectCurtailmentChange = new EventEmitter<boolean>();

  onCurtailmentChange(event: boolean) {
    this.projectCurtailmentChange.emit(event);
  }
}
