import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IProjectForm } from '../project-form.component';
import { CommonModule } from '@angular/common';
import { CalendarModule } from 'primeng/calendar';
import { ProjectDetailsResponse } from '@shared/interfaces';
import { DATE_FORMAT_PRIME_NG } from '@constants/date.const';
import { InputTemplateComponent } from '@shared/form-inputs/input-template/input-template.component';

@Component({
  selector: 'app-time-frame-form',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CommonModule, CalendarModule, InputTemplateComponent],
  templateUrl: './time-frame-form.component.html',
  styleUrl: './time-frame-form.component.scss',
})
export class TimeFrameFormComponent implements OnInit {
  @Input()
  set data(data: ProjectDetailsResponse | null) {
    this.projectDetails = data;
    this.populateFormData(this.projectDetails);
  }

  public projectDetails: ProjectDetailsResponse | null = null;

  projectForm: FormGroup<IProjectForm> | null = null;

  timeFrameFormControl: FormGroup | null = null;

  constructor(private parentControl: ControlContainer) {}

  ngOnInit(): void {
    this.projectForm = this.parentControl.control as FormGroup;
    this.timeFrameFormControl = this.parentControl.control?.get('timeframeForm') as FormGroup;
    if (this.projectDetails) {
      this.populateFormData(this.projectDetails);
    }
  }

  populateFormData(projectDetails: ProjectDetailsResponse | null) {
    if (!this.timeFrameFormControl) {
      return;
    }
    if (!projectDetails) {
      this.timeFrameFormControl?.reset();
      return;
    }

    this.populateGeneralForm(this.timeFrameFormControl, projectDetails);
  }

  populateGeneralForm(timeFrameFormControl: FormGroup, projectDetails: ProjectDetailsResponse) {
    timeFrameFormControl.get('codDate')?.setValue(new Date(projectDetails.cod_date));
    timeFrameFormControl.get('ntpDate')?.setValue(new Date(projectDetails.ntp_date));
    timeFrameFormControl.get('scDate')?.setValue(new Date(projectDetails.sc_date));
    timeFrameFormControl
      .get('pacDate')
      ?.setValue(projectDetails.pac_date ? new Date(projectDetails.pac_date) : null);
    timeFrameFormControl
      .get('iacDate')
      ?.setValue(projectDetails.iac_date ? new Date(projectDetails.iac_date) : null);
    timeFrameFormControl
      .get('facDate')
      ?.setValue(projectDetails.face_date ? new Date(projectDetails.face_date) : null);
  }

  get dateFormat(): string {
    return DATE_FORMAT_PRIME_NG;
  }
}
