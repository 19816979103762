import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { GeneralFormComponent } from './general-form/general-form.component';
import { TimeFrameFormComponent } from './time-frame-form/time-frame-form.component';
import { LegalFormComponent } from './legal-form/legal-form.component';
import { ButtonModule } from 'primeng/button';
import { get, trim } from 'lodash';
import { latitudeLongitudeValidator } from '@shared/validators/latitude-longitude/latitudeLongitudeValidator';
import { formatDate } from '@shared/utils/date-utils';
import { markFormGroupDirty } from '@shared/utils/form-utils';
import {
  CreateProjectPayload,
  ProjectDetailsResponse,
  ProjectLegalStructure,
  Region,
} from '@shared/interfaces';

import { CommonModule } from '@angular/common';
import { AttachmentFormComponent } from '@shared/components/attachment-form/attachment-form.component';
import { DocumentEntityType } from '@shared/enums/document/document';
import { AttachmentDocument, AttachmentFormData } from '@shared/interfaces/common.interface';
import { AttachmentListComponent } from '@shared/components/attachment-list/attachment-list.component';
import { getFormattedUrl } from '@shared/utils/common-utils';
import { FormMode, FormStatus } from '@shared/enums/form/form';

export interface IProjectForm {
  generalForm: never;
  timeframeForm: never;
  legalFormGroup: never;
  attachmentForm: never;
}

@Component({
  selector: 'app-project-form',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    AccordionModule,
    GeneralFormComponent,
    TimeFrameFormComponent,
    LegalFormComponent,
    ButtonModule,
    CommonModule,
    AttachmentFormComponent,
    AttachmentListComponent,
  ],
  templateUrl: './project-form.component.html',
  styleUrl: './project-form.component.scss',
})
export class ProjectFormComponent implements OnInit {
  @Input() regions: Region[] = [];

  @Input() submitButtonLabel: string;

  @Output() emitOnSubmit: EventEmitter<CreateProjectPayload> =
    new EventEmitter<CreateProjectPayload>();

  @Output() emitOnCancel: EventEmitter<CreateProjectPayload> =
    new EventEmitter<CreateProjectPayload>();

  @Input()
  set data(data: ProjectDetailsResponse | null) {
    this.projectDetails = data;
    this.populateForm();
  }

  @Input() mode: FormMode = FormMode.CREATE;

  @Input() set projectCurtailment(value: boolean) {
    if (this.projectForm) {
      this.projectForm.get('generalForm')?.get('projectCurtailment')?.setValue(value);
    }
  }

  public projectDetails: ProjectDetailsResponse | null = null;

  public projectForm!: FormGroup;

  public formStatus: FormStatus = FormStatus.InProgress;

  DocumentEntityType = DocumentEntityType;

  constructor(private readonly fb: FormBuilder) {
    this.submitButtonLabel = '';
  }

  ngOnInit(): void {
    this.projectForm = this.fb.group({
      generalForm: this.fb.group({
        siteId: [null],
        portfolio: [null, Validators.required],
        name: [null, Validators.required],
        status: [null, Validators.required],
        address: [null, Validators.required],
        coordinates: [null, [Validators.required, latitudeLongitudeValidator()]],
        region: [null, Validators.required],
        country: [null, Validators.required],
        city: [null, Validators.required],
        technology: [null, Validators.required],
        contractedDischargeHours: [null, [Validators.min(1), Validators.max(24)]],
        capacityDC: [null, Validators.min(0)],
        capacityAC: [null, Validators.min(0)],
        capacityBESS: [null, Validators.min(0)],
        capacityPOI: [null, Validators.min(0)],
        caisoResourceId: [null, [Validators.pattern(/^[a-zA-Z0-9_]*$/)]],
        caisoNodeId: [null],
        projectCurtailment: [true],
      }),
      timeframeForm: this.fb.group({
        codDate: [null, Validators.required],
        ntpDate: [null, Validators.required],
        scDate: [null, Validators.required],
        pacDate: [null],
        iacDate: [null],
        facDate: [null],
      }),
      attachmentFormLegal: this.fb.group({
        url: [null],
        documents: [],
      }),
      attachmentForm: this.fb.group({
        url: [null],
      }),
      legalFormGroup: new FormGroup(
        {
          companyNameSPV: new FormControl(null),
          legalRepresentativeSPV: new FormControl(null),
          legalFormList: this.fb.array([]),
        },
        [this.getLegalFormGroupValidator()],
      ),
    });

    this.projectForm.get('generalForm')?.get('country')?.disable();
    this.projectForm.get('generalForm')?.get('city')?.disable();
    this.populateForm();
  }

  getLegalFormGroupValidator(): ValidatorFn {
    return (control: unknown): Record<string, boolean> | null => {
      const formGroup = control as FormGroup;

      const companyName = trim(formGroup.get('companyNameSPV')?.value ?? '');
      const legalRepresentative = trim(formGroup.get('legalRepresentativeSPV')?.value ?? '');

      if (!companyName && legalRepresentative) {
        return { missingCompanyName: true };
      }

      return null;
    };
  }

  populateForm() {
    if (!this.projectDetails || !this.projectForm) {
      return;
    }

    this.projectForm
      .get('generalForm')
      ?.get('projectCurtailment')
      ?.setValue(this.projectDetails.curtailment_cap_status);
  }

  onSubmit() {
    if (this.projectForm.invalid) {
      this.projectForm.markAllAsTouched();
      markFormGroupDirty(this.projectForm);
      return;
    }

    const formRawValues = this.projectForm.getRawValue();

    const legalStructure: ProjectLegalStructure[] = get(
      formRawValues,
      'legalFormGroup.legalFormList',
    )?.map((item: unknown) => ({
      board_member_name: get(item, 'boardMemberSPV') ?? null,
      board_member_role: get(item, 'boardMemberRoleSPV') ?? null,
    }));

    const requestPayload = {
      site_id: get(formRawValues, 'generalForm.siteId'),
      portfolio: get(formRawValues, 'generalForm.portfolio'),
      name: get(formRawValues, 'generalForm.name'),
      is_archive: false,
      status: get(formRawValues, 'generalForm.status.name'),
      address: get(formRawValues, 'generalForm.address'),
      coordinates: get(formRawValues, 'generalForm.coordinates'),
      region: get(formRawValues, 'generalForm.region.id'),
      technology: get(formRawValues, 'generalForm.technology.name'),
      capacity_dc: get(formRawValues, 'generalForm.capacityDC'),
      capacity_ac: get(formRawValues, 'generalForm.capacityAC'),
      capacity_bess: get(formRawValues, 'generalForm.capacityBESS'),
      capacity_poi: get(formRawValues, 'generalForm.capacityPOI'),
      cod_date: formatDate(get(formRawValues, 'timeframeForm.codDate')),
      ntp_date: formatDate(get(formRawValues, 'timeframeForm.ntpDate')),
      sc_date: formatDate(get(formRawValues, 'timeframeForm.scDate')),
      pac_date: formatDate(get(formRawValues, 'timeframeForm.pacDate')),
      iac_date: formatDate(get(formRawValues, 'timeframeForm.iacDate')),
      face_date: formatDate(get(formRawValues, 'timeframeForm.facDate')),
      board_member_spv: null,
      legal_rep_spv: null,
      role: null,
      country: get(formRawValues, 'generalForm.country.id'),
      city: get(formRawValues, 'generalForm.city.id'),
      code: this.projectDetails?.code,
      url: get(formRawValues, 'attachmentForm.url'),
      legal_structures: legalStructure.filter(
        (item) => item.board_member_name && item.board_member_role,
      ),
      legal_structure_documents: get(formRawValues, 'attachmentFormLegal.documents')?.map(
        (document: AttachmentDocument) => ({
          id: document.id,
        }),
      ),
      legal_structure_url: get(formRawValues, 'attachmentFormLegal.url'),
      spv_company_name: get(formRawValues, 'legalFormGroup.companyNameSPV'),
      spv_legal_representative: get(formRawValues, 'legalFormGroup.legalRepresentativeSPV'),
      contracted_discharge_hours: get(formRawValues, 'generalForm.contractedDischargeHours'),
      caiso_resource_id: get(formRawValues, 'generalForm.caisoResourceId'),
      caiso_node_id: get(formRawValues, 'generalForm.caisoNodeId'),
      curtailment_cap_status: get(formRawValues, 'generalForm.projectCurtailment'),
    } as CreateProjectPayload;

    this.emitOnSubmit?.emit(requestPayload);
  }

  reset() {
    this.formStatus = FormStatus.InProgress;
    this.projectForm.reset();
  }

  onCancel() {
    this.emitOnCancel.emit();
  }

  get isFormSubmitDisabled(): boolean {
    return this.formStatus === FormStatus.Submitting;
  }

  get legalAttachmentFormData() {
    if (!this.projectDetails) {
      return null;
    }

    return {
      url: this.projectDetails.legal_structure_url,
      documents: this.projectDetails.legal_structure_documents,
    } as AttachmentFormData | null;
  }

  get legalStructureURL(): string {
    return this.projectDetails?.legal_structure_url ?? '';
  }

  get legalStructureDocuments(): AttachmentDocument[] {
    return this.projectDetails?.legal_structure_documents ?? [];
  }

  getFormattedUrl = getFormattedUrl;
}
