import { Component, Input, OnDestroy } from '@angular/core';
import { ProjectService } from '../../../features/project/services/project/project.service';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { WeatherData } from '@shared/interfaces/weather.interface';
import { ToastMessageService } from '@Services/toast-message/toast-message.service';
import { ToastNotificationSeverity } from '@shared/enums';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-weather-widget',
  standalone: true,
  imports: [DatePipe, DecimalPipe, CommonModule],
  templateUrl: './weather-widget.component.html',
  styleUrl: './weather-widget.component.scss',
})
export class WeatherWidgetComponent implements OnDestroy {
  @Input()
  set coordinates(cord: string | null) {
    if (!cord) {
      return;
    }
    this.lat = cord?.split(',')[0]?.trim();
    this.long = cord?.split(',')[1]?.trim();
    this.getWeatherData();
  }

  @Input()
  set appId(id: string | null) {
    if (!id) {
      return;
    }
    this.secretId = id;
    this.getWeatherData();
  }

  lat: string;
  long: string;

  weatherData: WeatherData;

  today: number = Date.now();

  subscription: Subscription;

  secretId: string;

  constructor(
    private readonly projectService: ProjectService,
    private readonly toastMessageService: ToastMessageService,
  ) {
    this.subscription = new Subscription();
  }

  getWeatherData(): void {
    if (!this.secretId || !this.lat || !this.long) {
      return;
    }

    const subscription = this.projectService
      .getWeatherData(this.lat, this.long, this.secretId)
      .subscribe({
        next: (res: WeatherData) => {
          this.weatherData = res;
        },
        error: () => {
          this.toastMessageService.emitMessage({
            summary: 'Error',
            details: 'Unable to Load weather Data.',
            severity: ToastNotificationSeverity.ERROR,
          });
        },
      });
    this.subscription.add(subscription);
  }

  celsiusToFahrenheit(celsius: number): number {
    return celsius * (9 / 5) + 32;
  }

  openWeatherDetails() {
    if (this.lat && this.long && this.weatherData?.id) {
      const url = `https://openweathermap.org/city/${this.weatherData?.id}`;
      window.open(url, '_blank');
    }
  }

  get weatherIcon(): string {
    if (!this.weatherData) {
      return '';
    }
    return `https://openweathermap.org/img/wn/${this.weatherData?.weather[0]?.icon}@2x.png`;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
