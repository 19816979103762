import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoggedInUserService } from '@Services/user/logged-in-user.service';
import { User } from '@shared/interfaces';
import { Subscription } from 'rxjs';
import { ProfileDropdownComponent } from './profile-dropdown/profile-dropdown.component';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { BadgeModule } from 'primeng/badge';
import { NotificationsService } from '@Services/notifications/notifications.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [ProfileDropdownComponent, RouterModule, ButtonModule, BadgeModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit, OnDestroy {
  user: User | null;

  subscriptions: Subscription;

  notificationBadgeCount: string | null;

  constructor(
    private readonly loggedInUserService: LoggedInUserService,
    private readonly notificationService: NotificationsService,
  ) {
    this.subscriptions = new Subscription();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.loggedInUserService.getLoggedInUser().subscribe((user) => {
        this.user = user;
      }),
    );

    this.subscriptions.add(
      this.notificationService.getNotificationStateObservable().subscribe((state) => {
        const count = state.new_notification_count;
        this.notificationBadgeCount = this.getNotificationBadgeCount(count);
      }),
    );
  }

  getNotificationBadgeCount(count: number | null): string | null {
    if (!count) {
      return null;
    }

    return count > 10 ? '10+' : `${count}`;
  }

  get firstName() {
    return this.user?.first_name ?? '-';
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
