import { noop } from 'lodash';
import { Component, Input, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { IProjectForm } from '../project-form.component';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { ProjectDetailsResponse, ProjectLegalStructure } from '@shared/interfaces';
import { FormMode } from '@shared/enums/form/form';
import { InputFieldComponent } from '@shared/form-inputs/input-field/input-field.component';

@Component({
  selector: 'app-legal-form',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CommonModule, InputFieldComponent, ButtonModule],
  templateUrl: './legal-form.component.html',
  styleUrl: './legal-form.component.scss',
})
export class LegalFormComponent implements OnInit {
  @Input()
  set data(data: ProjectDetailsResponse | null) {
    this.projectDetails = data;
    this.populateFormData(this.projectDetails);
  }

  @Input() mode: FormMode = FormMode.CREATE;

  public projectDetails: ProjectDetailsResponse | null = null;

  projectForm: FormGroup<IProjectForm> | null = null;

  legalFormList!: FormArray;

  legalFormGroup!: FormGroup;

  noop = noop;

  constructor(
    private readonly parentControl: ControlContainer,
    private readonly fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.projectForm = this.parentControl.control as FormGroup;
    this.legalFormGroup = this.parentControl.control?.get('legalFormGroup') as FormGroup;
    this.legalFormList = this.legalFormGroup?.get('legalFormList') as FormArray;
    this.items.clear();
    if (this.mode === FormMode.CREATE) {
      this.addNewItem();
    }
    if (this.projectDetails) {
      this.populateFormData(this.projectDetails);
    }
  }

  get items() {
    return this.legalFormList;
  }

  // Function to add a new item to the form array
  addNewItem() {
    this.items.push(this.getNewLegalFormItem());
  }

  getNewLegalFormItem(): FormGroup {
    return this.fb.group({
      boardMemberSPV: [''],
      boardMemberRoleSPV: [''],
    });
  }

  // Function to remove an item from the form array
  removeItem(index: number) {
    this.items.removeAt(index);
  }

  get itemsLength() {
    return this.items?.length || 0;
  }

  populateFormData(projectDetails: ProjectDetailsResponse | null) {
    if (!this.legalFormList) {
      return;
    }
    if (!projectDetails) {
      this.legalFormList?.reset();
      return;
    }

    if (!this.items) {
      return;
    }

    if (this.items?.length > 0) {
      this.removeAllItems();
    }

    this.populateProjectLegalForm(projectDetails);
  }

  removeAllItems() {
    while (this.items.length !== 0) {
      this.items.removeAt(0);
    }
  }

  populateProjectLegalForm(projectDetails: ProjectDetailsResponse) {
    const projectLegalStructure: ProjectLegalStructure[] =
      projectDetails.legal_structures as ProjectLegalStructure[];
    this.legalFormGroup.get('companyNameSPV')?.setValue(projectDetails.spv_company_name ?? '');
    this.legalFormGroup
      .get('legalRepresentativeSPV')
      ?.setValue(projectDetails.spv_legal_representative ?? '');

    if (!projectDetails.legal_structures || projectDetails.legal_structures.length === 0) {
      return;
    }

    projectLegalStructure.forEach((legalStructure) => {
      const form = this.getNewLegalFormItem();
      form.get('boardMemberSPV')?.setValue(legalStructure.board_member_name ?? '');
      form.get('boardMemberRoleSPV')?.setValue(legalStructure.board_member_role ?? '');
      this.items.push(form);
    });
  }
}
