import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputSwitchChangeEvent, InputSwitchModule } from 'primeng/inputswitch';

@Component({
  selector: 'app-project-curtailment',
  standalone: true,
  imports: [FormsModule, CommonModule, InputSwitchModule],
  templateUrl: './project-curtailment.component.html',
  styleUrl: './project-curtailment.component.scss',
})
export class ProjectCurtailmentComponent {
  @Input() projectCurtailment = false;

  @Output() projectCurtailmentChange = new EventEmitter<boolean>();

  onCurtailmentChange(event: InputSwitchChangeEvent) {
    this.projectCurtailmentChange.emit(event.checked);
  }
}
