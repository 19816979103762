<div *ngIf="projectForm && generalFormControl" class="d-flex g-13">
  <div class="full-width custom-form" [formGroup]="generalFormControl">
    <div class="d-flex g-15 justify-content-start flex-wrap">
      <!-- Site -->
      <ng-container *ngTemplateOutlet="autoCompleteTemplate"></ng-container>

      <!-- Portfolio -->
      <div class="form-input">
        <app-input-field
          [id]="'portfolio'"
          name="portfolio"
          label="Portfolio"
          [required]="true"
          formControlName="portfolio"
          placeholder="Portfolio"
          [error]="
            generalFormControl.get('portfolio')?.invalid &&
            generalFormControl.get('portfolio')?.touched
          "
        />
      </div>

      <!-- Name -->
      <div class="form-input">
        <app-input-field
          [id]="'name'"
          name="name"
          label="Name"
          [required]="true"
          formControlName="name"
          placeholder="Name"
          [error]="
            generalFormControl.get('name')?.invalid && generalFormControl.get('name')?.touched
          "
        />
      </div>

      <!-- Status -->
      <div class="form-input">
        <app-input-template
          label="Status"
          [required]="true"
          [error]="
            generalFormControl.get('status')?.invalid && generalFormControl.get('status')?.dirty
          "
          for="project-status"
        >
          <ng-template #inputTemplate let-error="error">
            <p-dropdown
              id="project-status"
              name="project-status"
              [options]="projectStatus"
              optionLabel="name"
              [showClear]="false"
              placeholder="Select a Status"
              formControlName="status"
              class="full-width"
              [ngClass]="{ 'error-dropdown': error }"
            >
              <ng-template pTemplate="selectedItem">
                <div class="selected-status-chip">
                  <div [class]="getProjectStatusItemClass(status)">{{ status }}</div>
                </div>
              </ng-template>
              <ng-template let-status pTemplate="item">
                <div [class]="getProjectStatusItemClass(status && status.name)">
                  {{ status && status.name }}
                </div>
              </ng-template>
            </p-dropdown>
          </ng-template>
        </app-input-template>
      </div>

      <!-- Address -->
      <div class="form-input">
        <app-input-field
          [id]="'address'"
          name="address"
          label="Address"
          [required]="true"
          formControlName="address"
          placeholder="Address"
          icon="pi pi-map-marker"
          iconPosition="right"
          [error]="
            generalFormControl.get('address')?.invalid && generalFormControl.get('address')?.touched
          "
        />
      </div>

      <!-- Coordinates -->
      <div class="form-input">
        <app-input-field
          [id]="'coordinates'"
          name="coordinates"
          label="Coordinates"
          [required]="true"
          formControlName="coordinates"
          placeholder="Latitude, Longitude"
          icon="pi pi-map-marker"
          iconPosition="right"
          [error]="
            generalFormControl.get('coordinates')?.invalid &&
            generalFormControl.get('coordinates')?.touched
          "
          [errorMessage]="
            coordinationFormatValidation
              ? 'Invalid format; coordinates should be in the format latitude, longitude'
              : ''
          "
        />
      </div>

      <!-- Region -->
      <div class="form-input">
        <app-input-template
          label="Region"
          [required]="true"
          [error]="
            generalFormControl.get('region')?.invalid && generalFormControl.get('region')?.dirty
          "
          for="project-region"
        >
          <ng-template #inputTemplate let-error="error">
            <p-dropdown
              name="project-region"
              id="project-region"
              [options]="regions"
              optionLabel="name"
              [showClear]="false"
              placeholder="Select a Region"
              formControlName="region"
              class="full-width"
              [ngClass]="{ 'error-dropdown': error }"
              (onChange)="onRegionChange($event.value)"
            >
              <ng-template pTemplate="selectedItem">
                {{ region }}
              </ng-template>
              <ng-template let-region pTemplate="item">
                {{ region && region.name }}
              </ng-template>
            </p-dropdown>
          </ng-template>
        </app-input-template>
      </div>

      <!-- Country -->
      <div class="form-input">
        <app-input-template
          label="Country"
          [required]="true"
          [error]="
            generalFormControl.get('country')?.invalid && generalFormControl.get('country')?.dirty
          "
          for="country"
        >
          <ng-template #inputTemplate let-error="error">
            <p-dropdown
              id="country"
              name="country"
              [options]="countries"
              optionLabel="name"
              [showClear]="false"
              formControlName="country"
              placeholder="Select a Country"
              [ngClass]="{ 'error-dropdown': error }"
              (onChange)="onCountryChange($event.value)"
            >
              <ng-template pTemplate="selectedItem">
                <div class="d-flex justify-content-start g-13" *ngIf="countryName">
                  <img
                    src="assets/flags/flag_placeholder.png"
                    alt="_"
                    [class]="'flag flag-' + countryCode"
                  />
                  <div>
                    {{ countryName }}
                  </div>
                </div>
              </ng-template>
              <ng-template let-country pTemplate="item">
                <div class="d-flex justify-content-start g-13">
                  <img
                    src="assets/flags/flag_placeholder.png"
                    alt="_"
                    [class]="'flag flag-' + (country.code && country.code.toLowerCase())"
                  />
                  <div>{{ country.name }}</div>
                </div>
              </ng-template>
            </p-dropdown>
          </ng-template>
        </app-input-template>
      </div>

      <!-- City -->
      <div class="form-input">
        <app-input-template
          label="City"
          [required]="true"
          [error]="generalFormControl.get('city')?.invalid && generalFormControl.get('city')?.dirty"
          for="project-city"
        >
          <ng-template #inputTemplate let-error="error">
            <p-dropdown
              name="city"
              id="project-city"
              [options]="cities"
              optionLabel="name"
              [showClear]="false"
              placeholder="Select City"
              formControlName="city"
              [virtualScroll]="true"
              [itemSize]="40"
              [ngClass]="{
                'error-dropdown': error,
                'custom-disabled': !cities || cities.length === 0,
              }"
              emptyMessage="No record found."
            >
              <ng-template pTemplate="selectedItem">
                {{ city }}
              </ng-template>
              <ng-template let-city pTemplate="item">
                {{ city && city.name }}
              </ng-template>
            </p-dropdown>
          </ng-template>
        </app-input-template>
      </div>

      <!-- Resource ID (CAISO) -->
      <div class="form-input">
        <app-input-field
          [id]="'resource-id'"
          name="resource-id"
          label="Resource ID (CAISO)"
          [required]="false"
          [disabled]="generalFormControl.get('caisoResourceId')?.disabled || false"
          formControlName="caisoResourceId"
          placeholder="Resource ID"
          [error]="
            generalFormControl.get('caisoResourceId')?.invalid &&
            generalFormControl.get('caisoResourceId')?.touched
          "
        />
        @if (
          generalFormControl.get('caisoResourceId')?.errors?.['pattern'] &&
          generalFormControl.get('caisoResourceId')?.touched
        ) {
          <div class="error-message">
            Resource ID should only contains alphanumeric characters and underscore.
          </div>
        }
      </div>

      <!-- Node ID (CAISO) -->
      <div class="form-input">
        <app-input-field
          [id]="'node-id'"
          name="node-id"
          label="Node ID (CAISO)"
          [required]="false"
          [disabled]="generalFormControl.get('caisoNodeId')?.disabled || false"
          formControlName="caisoNodeId"
          placeholder="Node ID"
          [error]="
            generalFormControl.get('caisoNodeId')?.invalid &&
            generalFormControl.get('caisoNodeId')?.touched
          "
        />
      </div>

      <!-- Technology -->
      <div class="form-input">
        <app-input-template
          label="Technology"
          [required]="true"
          [error]="
            generalFormControl.get('technology')?.invalid &&
            generalFormControl.get('technology')?.dirty
          "
          for="project-technology"
        >
          <ng-template #inputTemplate let-error="error">
            <p-dropdown
              name="technology"
              id="project-technology"
              [options]="technologies"
              optionLabel="name"
              [showClear]="false"
              placeholder="Select Technology"
              formControlName="technology"
              [ngClass]="{ 'error-dropdown': error }"
            >
              <ng-template pTemplate="selectedItem">
                {{ technology }}
              </ng-template>
              <ng-template let-technology pTemplate="item">
                {{ technology && technology.name }}
              </ng-template>
            </p-dropdown>
          </ng-template>
        </app-input-template>
      </div>

      <!-- Contracted Discharge Hours -->
      <div class="form-input">
        <app-input-field
          [id]="'contractedDischargeHours'"
          name="contractedDischargeHours"
          type="number"
          label="Contracted Discharge Hours"
          [required]="false"
          [disabled]="isContractedHoursDisabled"
          formControlName="contractedDischargeHours"
          placeholder="Contracted Discharge Hours"
          iconType="text"
          icon="Hrs"
          [error]="
            generalFormControl.get('contractedDischargeHours')?.invalid &&
            generalFormControl.get('contractedDischargeHours')?.touched
          "
        />
      </div>

      <!-- Capacity DC -->
      <div class="form-input">
        <app-input-field
          [id]="'capacity-dc'"
          name="capacity-dc"
          type="number"
          label="Capacity DC"
          [required]="true"
          formControlName="capacityDC"
          placeholder="Capacity DC"
          iconType="text"
          icon="MW"
          [error]="
            generalFormControl.get('capacityDC')?.invalid &&
            generalFormControl.get('capacityDC')?.touched
          "
        />
      </div>

      <!-- Capacity AC -->
      <div class="form-input">
        <app-input-field
          [id]="'capacity-ac'"
          name="capacity-ac"
          type="number"
          label="Capacity AC"
          [required]="true"
          formControlName="capacityAC"
          placeholder="Capacity AC"
          iconType="text"
          icon="MW"
          [error]="
            generalFormControl.get('capacityAC')?.invalid &&
            generalFormControl.get('capacityAC')?.touched
          "
        />
      </div>

      <!-- Capacity BESS -->
      <div class="form-input capacity-bess">
        <app-input-field
          [id]="'capacity-bess'"
          name="capacityBESS"
          type="number"
          label="Capacity BESS"
          [required]="true"
          formControlName="capacityBESS"
          placeholder="Capacity BESS"
          iconType="text"
          icon="MW/MWh"
          [error]="
            generalFormControl.get('capacityBESS')?.invalid &&
            generalFormControl.get('capacityBESS')?.touched
          "
        />
      </div>

      <!-- Capacity POI -->
      <div class="form-input">
        <app-input-field
          [id]="'capacity-poi'"
          name="capacity-poi"
          type="number"
          label="Capacity POI"
          [required]="true"
          formControlName="capacityPOI"
          placeholder="Capacity POI"
          iconType="text"
          icon="MW"
          [min]="0"
          [max]="9999999999"
          [error]="
            generalFormControl.get('capacityPOI')?.invalid &&
            generalFormControl.get('capacityPOI')?.touched
          "
        />
      </div>
    </div>
  </div>

  <ng-template #autoCompleteTemplate>
    <div class="form-input">
      <app-input-template
        label="Site"
        [error]="generalFormControl.get('site')?.invalid && generalFormControl.get('site')?.dirty"
        for="project-region"
      >
        <ng-template #inputTemplate let-error="error">
          <p-autoComplete
            styleClass="site-auto-complete"
            placeholder="Site code and name"
            [suggestions]="sites"
            (completeMethod)="loadSiteItemsLazy($event)"
            (onSelect)="onSiteSelect($event)"
            [ngModel]="selectedSiteCode"
            [minLength]="2"
            [dropdown]="false"
            [forceSelection]="true"
            optionLabel="code"
            [searchMessage]="'Searching Site...'"
            [emptySelectionMessage]="'No Site found'"
            [delay]="200"
            (onClear)="onClear()"
            (onUnselect)="onUnselect()"
          >
            <!-- delay is debounce effect -->
            <ng-template let-site pTemplate="item">
              <div class="pl-5 pr-5 searched-site">{{ getSiteName(site) }}</div>
            </ng-template>
          </p-autoComplete>
        </ng-template>
      </app-input-template>
    </div>
  </ng-template>
</div>
